import pokies from "@theme/icons/navigation/pokies.inline.svg";
import arena from "@theme/icons/navigation/arena.inline.svg";
import table from "@theme/icons/navigation/table.inline.svg";
import bet from "@theme/icons/navigation/bet.inline.svg";
import eSport from "@theme/icons/navigation/esport.inline.svg";
import bonuses from "@theme/icons/navigation/bonuses.inline.svg";
import vip from "@theme/icons/navigation/vip.inline.svg";
import profile from "@theme/icons/navigation/profile.inline.svg";
import more from "@theme/icons/navigation/more.inline.svg";
import faq from "@theme/icons/navigation/faq.inline.svg";

export const iconsNavigation: Record<string, string> = {
    // bottom
    pokies,
    bet,
    arena,

    // sidebar
    table,
    eSport,
    bonuses,
    vip,
    profile,
    faq,

    // common
    more,
};
